<template>
  <b-alert
    show
    variant="danger"
    class="text-center"
  >
    <div class="alert-body">
      <FeatherIcon icon="InfoIcon" />
      Müşteri kartı için açık {{ openData.count }} adet teklif bulundu. Detaylar için müşteri geçmişini inceleyin.
    </div>
  </b-alert>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'OpenCard',
  components: {
    BAlert,
  },
  computed: {
    openData() {
      return this.$store.getters['offers/openData']
    },
  },
}
</script>
